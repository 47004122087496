var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _vm.FormData
            ? _c("form-create", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "fc",
                staticClass: "formBox",
                attrs: {
                  option: _vm.option,
                  rule: _vm.FormData.rule,
                  "handle-icon": "false",
                  cmovies: _vm.movies,
                },
                on: { "on-submit": _vm.onSubmit },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "商品信息",
                visible: _vm.dialogVisible,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "box-container" }, [
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("商品名称："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.FormData.name)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("直播价："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.FormData.price)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [_vm._v("库存：")]),
                      _vm._v(" "),
                      _vm.FormData.product
                        ? _c("span", { staticClass: "info" }, [
                            _vm._v(_vm._s(_vm.FormData.product.stock)),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp100 image" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("商品图："),
                      ]),
                      _vm._v(" "),
                      _vm.FormData.product
                        ? _c("img", {
                            staticStyle: {
                              "max-width": "150px",
                              height: "80px",
                            },
                            attrs: { src: _vm.FormData.cover_img },
                          })
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("审核结果："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("liveReviewStatusFilter")(
                              _vm.FormData.status
                            )
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp100" }, [
                      _c("label", { staticClass: "name" }, [_vm._v("备注：")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "info" },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", rows: 1 },
                            model: {
                              value: _vm.FormData.mark,
                              callback: function ($$v) {
                                _vm.$set(_vm.FormData, "mark", $$v)
                              },
                              expression: "FormData.mark",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "list sp100 mt20" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass:
                              "el-button el-button--primary el-button--medium",
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "button",
                              disabled: _vm.FormData.mark == "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleRemarks()
                              },
                            },
                          },
                          [_vm._v("提交")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.importVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "商品信息",
            visible: _vm.importVisible,
            width: "700px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.importVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "divBox" },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "直播间名称：",
                                "label-width": "150px",
                              },
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.broadcast_room))])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "直播间ID：",
                                "label-width": "150px",
                              },
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.broadcast_id))])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "box-card" },
                [
                  _vm.importVisible
                    ? _c("form-create", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        ref: "fc",
                        staticClass: "formBox",
                        attrs: {
                          rule: _vm.FormData.rule,
                          "handle-icon": "false",
                        },
                        on: { "on-submit": _vm.onSubmit },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }